@import '../../style/custonVariable';
.container{
    padding: 15px 25px;
    .Card{
        margin-bottom: 20px;
        .CardBody{
            padding: 15px;
            .fileContent{
                border: 1px solid #bce8f1;
                .partyImageUpload{
                    padding: 15px;
                    .fileUplaod{
                        margin-bottom: 9px;
                        display: block;
                        overflow: hidden;
                        .thumbnail{
                            width: 50px;
                        }
                    }
                }
            }
            .removeBtn{
                position: absolute;
            }
            .uploadButtons{
                position: relative;
                overflow: hidden;
                display: inline-block;
                .fileUplaod{
                    position: absolute;
                    top: 0;
                    right: 0;
                    margin: 0;
                    opacity: 0;
                    filter: alpha(opacity=0);
                    transform: translate(-300px, 0) scale(4);
                    font-size: 23px;
                    direction: ltr;
                    cursor: pointer;
                }
            }
        }
        .datePicker{
            display: block;
            position: relative;
            float: none;
            inset: auto;
            margin: 0px;
            padding: 0px;
            width: 100% !important;
            .datePickerInput{
                padding-left: 40px;
                padding-right: 12px;
                position: relative;
                float: none;
                inset: auto;
                margin: 0px;
                background-color: #eee;
                opacity: 1;
            }
            .pickerIcon{
                top: 9px;
                left: 12px;
                border: none;
                cursor: pointer;
                display: block;
                height: 16px;
                line-height: 0;
                padding: 0;
                position: absolute;
                text-indent: -9000px;
                width: 16px;
            }
        }
    }
    .datatable{
        padding: 12px 12px 12px 12px !important;
    }
    .addbutton {
        padding: 12px 12px 12px 12px !important;
    }
}
.badge{
    display: inline-block;
    padding: .35em .5em;
    font-size: 14px;
    font-weight: 500;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 3.25rem;
    min-width: 10px;
    text-shadow: 0 1px 0 rgba(252, 213, 213, 0.2);
    // &.amount{
    //     color: #7266ba;
    //     background-color: #7266ba54;
    // }
    // &.balanceAmount{        
    //     color: #11a666;
    //     background-color: #dbf2e8;
    // }
    // &.debit{
    //     color: #f85974;
    //     background-color: #f6dee2;
    // }
}

.addguestContainer{
    padding: 15px;
}
.panel{
    border-radius: 2px;
    // box-shadow: 0 0 0.875rem 0 rgb(53 64 82 6%);
    margin-bottom: 20px;
    background-color: #fff;
    // border: 1px solid transparent;
    padding: 10px;
}
.panel_default {
    border-color: #dee5e7;
}
.panel_body{
    padding: 15px;
    // margin-top: 41px;
    // :after
    // {
    //     display: table;
    //     content: " ";
    //     clear: both;
    // }
}

.dark_mode_panel{
    border-radius: 2px;
    // box-shadow: 0 0 0.875rem 0 rgb(53 64 82 6%);
    margin-bottom: 20px;
    //background-color: #fff;
    // border: 1px solid transparent;
    padding: 10px;
}
.text_primary{
    color: #7266ba;
}
.isInvalid{
    margin-top: 4px;
    color: #a94442;
    font-size: 0.875em;
    font-weight: 400;

}
.popupButtonArea{
    background-color: #201f2b;
    width: max-content;
    padding: 12px 25px;
    border-radius: 30px;
    text-align: center;
    position: fixed;
    bottom: 2%;
    z-index: 1111;
    right: 0px;
    left: 230px;
    margin-right: auto;
    margin-left: auto;
    @media (min-width: 1400px) {
            width: 25%; 
    }
    ul{
        padding: 0;
        list-style: none;
        margin-bottom: 0;
        li{ 
            display: inline-flex;
            margin-right: 15px;
            color: #fff;
            font-size: 18px;
            span i{
                cursor: pointer;
            }
            .CountRound{
                background-color: #3c3c4c;
                width: 38px;
                height: 24px;
                padding: 11px;
                border-radius: 13px;
                color: #fff;
                font-size: 16px;
                display: block;
                line-height: 1px;
                margin-right: 15px;
                font-weight: 600;
            }
            .spanText{
                height: 24px;
                padding: 11px;
                color: #fff;
                font-size: 16px;
                display: block;
                line-height: 1px;
                margin-right: 15px;
            }
            .CancelButton{
                background-color: #ec3751 !important;
                border: none;
                border-radius: 30px;
                padding: 10px 15px;
            }
        }
    }
}