@import "../../../style/custonVariable";
.container{
    padding: 15px;
    .BoardList{
        color: #dcf2f8;
        background-color: #23b7e5;
        border-color: #dee5e7;
        border-radius: 2px;
        // box-shadow: ;
        margin-bottom: 20px;
        .body{
            padding: 15px;
            text-align: center;
            .inline{
                display: inline-block;
                .thumbnail{
                    display: inline-block;
                    width: 128px;
                    .iconBtn{
                        color: $color-white;
                        font-size: 30px;
                    }
                }
                hr{
                    margin-top: 20px;
                    margin-bottom: 20px;
                    border: 0;
                    border-top: 1px solid #dcdcdc;
                }
            }
            .boardName{
                margin-bottom: 5px;
                font-size: 24px;
            }
        }
    }
    .tablePanel{
        padding: 0;
        .boardView{
            color: #7266ba;
            font-size: 24px;
        }
    }
}
.treeContainer{
    padding: 15px 25px;
    .actionContent{
        margin-top: 35px;
        border-color: #dee5e7;
        border-radius: 2px;
        .panelBody{
            padding: 15px;
            .pullRight{
                float: right;
            }
        }
    }
    .treeMain{
        position: relative;
        overflow: auto !important;
        .chart{
            transform: matrix(1, 0, 0, 1, 0, 0);
            overflow: scroll;
            min-width: 100%;
            text-align: -webkit-center;
            // margin-top: 30px;
            transform-origin: 0% 0% 0px !important;
            margin-bottom: 20px;
            margin-top: 56px;
            .mainTable{
                zoom: 1;
                transform-origin: 0px 0px;
                background-color: transparent;
                border-spacing: 0;
                border-collapse: collapse;
                text-align: center;
                td{
                    text-align: center;
                    vertical-align: top;
                    padding: 0; 
                    .node{
                        display: inline-block;
                        margin: -5px 25px;
                        z-index: 10;
                        overflow: hidden;
                        word-break: break-all;
                        font-size: 12px !important;
                        background: rgba(0, 0, 0, 0) linear-gradient(to right, rgb(114, 102, 186) 0%, rgb(48, 30, 156) 100%) repeat scroll 0 0;
                        border: 2px solid #ccc;
                        color: #fff;
                        height: 80px;
                        width: 85px;
                    }
                    
                }
            }
            .Row{
                margin: 0px auto;
                .TableDiv{
                    // width: 100px !important;
                    padding-right: 5px;
                    margin-top: 5px;
                    height: 100px;
                    border-top: none;
                    float: left;
                    color: $color-white;
                    .inactive{
                        height: 100px;
                        border-radius: 5px;
                        border: 1px solid #26C3E0;
                        padding-top: 37px;
                        width: 100%;
                        text-align: center;
                        overflow: hidden;
                        word-break: break-all;
                        background: #7AE4F7;
                    }
                    .active{
                        height: 100px;
                        border-radius: 5px;
                        border: 1px solid #1095BF;
                        padding-top: 37px;
                        width: 100%;
                        text-align: center;
                        overflow: hidden;
                        word-break: break-all;
                        background: #04B7E0;
                    }
                }
            }
        }
    }
}
.line{
    height: 24px;
    width: 2px!important;
    overflow: hidden;
    font-size: 0;
}
.down{
    background-color: #6F6F6F !important;
    margin: 0 auto;
}
.left{
    border-right: 2px solid #6F6F6F !important;
}
.right{
    border-left: 2px solid #fff !important;
}
.top{
    border-top: 2px solid #6F6F6F !important;
}