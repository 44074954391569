@import '../../style/custonVariable';
.container {
    padding: 15px;
    .panelBody{
        padding: 15px;
    }
    .cartItemImage {
        width: 280px;
        height: 270px;
    }
    .itemTitle {
        margin-bottom: 5px;
        margin-top: 35px;
        text-align: center;
    }
    .categoryTxt {
        display: inline;
        padding: .2em .6em .3em;
        font-size: 75%;
        font-weight: bold;
        line-height: 1;
        color: #fff;
        text-align: center;
        white-space: nowrap;
        vertical-align: baseline;
        border-radius: .25em;

        text-shadow: none;
        margin-left: 10px;
        color: #58666e;
        background-color: #edf1f2;
    }
    .textCenter {
        text-align: center;
    }
    .price {
        font-size: 15px;
        font-weight: 400;
        color: #7266ba;
        text-align: center;
    }
    .noGutter {
        margin-right: 0;
        margin-left: 0;
    }
    .wrapper {
        padding: 15px
    }
    .br {
        border-right: 1px solid #d4d6d6;
    }
    .btnAddon {
        &.btnAddonSuccess {
            background: $color-success;
            border-color: $color-success;
            
            &:not(:disabled):not(.disabled):active {
                background: $color-success;
                border-color: $color-success;
            }
        }

        &.btnAddonInfo {
            background: $color-info;
            border-color: $color-info;
            
            &:not(:disabled):not(.disabled):active {
                background: $color-info;
                border-color: $color-info;
            }
        }
        
        i {
            position: relative;
            float: left;
            width: 34px;
            height: 34px;
            margin: -7px -16px;
            margin-right: 5px;
            line-height: 34px;
            text-align: center;
            background-color: rgba(0,0,0,0.1);
            border-radius: 2px 0 0 2px;        
        }
    } 

    .noPadder {
        padding: 0 !important;
    }

    .width100 {
        width: 100%;
    }

    .displayFlex {
        display: flex;
    }

    .infoLabel {
        display: inline;
        position: relative;
        padding: .2em .6em .3em;
        line-height: 1;
        text-align: center;
        white-space: nowrap;
        vertical-align: baseline;
        border-radius: .25em;
        margin-right: 15px;
        font-size: 13px;
        color: #dcf2f8;
        background-color: #23b7e5;
    }
}
legend{
    display: block;
    width: 100%;
    padding: 0;
    margin-bottom: 21px;
    padding-bottom: 12px;
    font-size: 18px;
    line-height: inherit;
    color: #333;
    border: 0;
    border-bottom: 1px solid #e5e5e5;
}
.cartButton{
    background-color: $color-primary;
    border-color: $color-primary;
    height: 34px;
    &:hover,&:disabled{
        background-color: $color-primary;
        border-color: $color-primary;
    }
}