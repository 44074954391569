.stepViewContainer {
    margin-top: 10px;
    margin-left: 10px;
    .stepId {
        height: 100px;
        align-content: center;
        top: 1px;
        line-height: 82px;
        margin: 5px 2px;
    }

    .node {
        padding-right: 5px;
        margin-top: 5px;
        height: 100px;
        border-top: none;
        float: left;
        .nodeItem {
            height: 100px;
            color: white;
            border-radius: 5px;
            border: 1px solid #1095BF;
            padding-top: 37px;
            width: 100%;
            text-align: center;
            overflow: hidden;
            word-break: break-all;
            background: -webkit-linear-gradient(top, #7fcbdc 0%,#4c9db1 100%);
        }
    }
}