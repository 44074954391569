@import "../../style/custonVariable";
.container{
    padding: 15px;
    table{
        background: $color-white;
        .actionIcon{
            color: #7266ba;
            font-size: 18px;
        }
    }
}
.modalContent{
    border: none;
    width: 786px;
    .modalHeader{
        background-color: #7266ba;
        text-align: center;
        color: $color-white;
        display: block;
        button{
            padding: 1rem 1rem;
            margin: -38px -10px -25px auto;
            color: $color-white;
            font-size: large;
            span{
                font-size: 28px;
            }
        }
    }
    .invoiceBody{
        padding: 15px;
        .printButton{
            text-align: right;
        }
        .printArea{
            border: 1px solid #dee5e7;
        }
        .bordered{
            td,th{
                border: 1px solid #ddd;
                vertical-align: middle !important;
            }
        }
        .tableBottom{
            tr{
                border-bottom: 1px solid #c8c8c8;
            }
            td{
                padding: 5px;
            }
        }
    }
}


/****************************************** dark mode *****************************************/ 

.dark_mode_container{
    padding: 15px;
    table{
        //background: $color-white;
        .actionIcon{
            color: #7266ba;
            font-size: 18px;
        }
    }
}
.modalContent{
    border: none;
    width: 786px;
    .dark_mode_modalHeader{
        background-color: #7266ba;
        text-align: center;
        //color: $color-white;
        display: block;
        button{
            padding: 1rem 1rem;
            margin: -38px -10px -25px auto;
        }
    }
    .invoiceBody{
        padding: 15px;
        .printButton{
            text-align: right;
        }
        .printArea{
            border: 1px solid #dee5e7;
        }
        .bordered{
            td,th{
                border: 1px solid #ddd;
                vertical-align: middle !important;
            }
        }
        .tableBottom{
            tr{
                border-bottom: 1px solid #c8c8c8;
            }
            td{
                padding: 5px;
            }
        }
    }
}

/************************************** end of dark mode **************************************/