.notFount{
    position: relative;
    height: 100vh;
    .notFountContent{
        position: absolute;
        left: 50%;
        top: 40%;
        transform: translate(-50%, -50%);
        max-width: 920px;
        width: 100%;
        line-height: 1.4;
        text-align: center;
        padding-left: 15px;
        padding-right: 15px;
        .notfount404{
            position: static;
            height: 410px;
            top: -390px;
            width: 100%;
            left: 50%;
            background-position: center;
            z-index: -1;
            background-image: url(../../../assets/images/icons/error-404.png);
            background-repeat: no-repeat;
           
            @media (max-width: 768px){
                height: 300px;
                background-size: 100%;
            }
            h1{
                color: #7266ba29;
                font-weight: 900;
                font-size: 276px;
                margin: 0px;
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
            }
        }
        h2{
            font-family: 'Maven Pro', sans-serif;
            font-size: 46px;
            color: #1ec8ef;
            font-weight: 900;
            text-transform: uppercase;
            margin: 0px;
            font-size: 26px;
            span{
                color: red;
                font-size: 35px;
            }
        }
        p{
            color: #000;
            font-weight: 400;
            text-transform: uppercase;
            margin-top: 15px;
            font-size: 15px;
            margin-bottom: 3rem;
        }
        .backToDashboard{
            text-decoration: none;
    text-transform: uppercase;
    background: #35d0f3;
    display: inline-block;
    padding: 16px 28px;
    box-shadow: 3px 5px 20px #35d0f369;
    border: 2px solid transparent;
    border-radius: 40px;
    color: #fff;
    font-weight: 400;
    transition: 0.2s all;
        }
    }
}