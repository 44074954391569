.mailbox-nav{
    .nav-link {
        color: rgb(88, 102, 110) !important;
        &.active{
            background-color : #40b7e5 !important;
            color: #fff !important;
        }
        span{
            i{
                padding: 5px;
            }
        }
    }
}