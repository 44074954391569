body {
  background-image: none !important;
  background-color: #fff !important;
}
.loginNavBrand {
  width: 200px;
  margin: 0 auto 20px;
}
.loginNavBrandImg {
  width: 100%;
}
.loginForms {
  // background-color: #f7f7f7 !important;
  padding: 20px 15px !important;
  // border-radius: 18px!important;
  // border: 0;
  font-size: 15px;
  border-radius: 5px;
  height: 30px !important;
  // box-shadow: 2px 5px 10px #e7e7e7;
  &:focus {
    border-color: #d8d8d8;
  }
}
.loginFormsHasError {
  box-shadow: none;
  border: 1px solid #ead3d3 !important;
}
.isInvalid {
  margin-top: 4px;
  margin-left: 23px;
  color: #a94442;
}
.loginButton {
  text-align: center;
}
.loginFormBtn {
  width: auto;
  margin: 3px auto;
  padding: 10px 60px;
  // border-radius: 18px;
  color: #fff !important;
  background-color: #70a9ff !important;
  border-color: #70a9ff;
  cursor: pointer;
  border: none;
  border-radius: 30px;
  box-shadow: 0px 10px 20px #bdd7ff;
}

.dark_mode_loginFormBtn {
  width: auto;
  margin: 3px auto;
  padding: 10px 60px;
  // border-radius: 18px;
  color: #4a4040 !important;
  background-color: #98cbff !important;
  border-color: #70a9ff;
  cursor: pointer;
  border: none;
  border-radius: 30px;
  //box-shadow: 0px 10px 20px #bdd7ff;
}

//login form
.LoginFormsContainer {
  background: rgba(255, 255, 255, 1);
  padding: 30px 30px 25px 30px;
  border-radius: 2px;
  // box-shadow: 0px 1px 8px rgb(0 0 0 / 20%);
}
.ForgotSection {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(50%, 1fr));
  justify-content: space-between;
  position: relative;
  z-index: 9;
  margin: -10px 1px 22px;
  a {
    color: #242424;
    font-size: 15px;
    text-decoration: none;
    &:hover {
      color: #70a9ff;
    }
  }
  .admin_login_lnk:last-child {
    text-align: right;
  }
}
.lg_login_btn_btm {
  width: 100%;
  text-align: center;
  color: #242424;
  margin-top: 20px;
  font-size: 15px;
  text-decoration: none;
  a {
    width: 100%;
    text-align: center;
    color: #4690ff;
    font-size: 15px;
    text-decoration: none;
    &:hover {
      color: #3b6fbd;
    }
  }
}
.langContainer {
  display: block !important;
}

.dark_mode_lg_login_btn_btm {
  width: 100%;
  text-align: center;
  color: #fff;
  margin-top: 20px;
  font-size: 15px;
  text-decoration: none;
  a {
    width: 100%;
    text-align: center;
    color: #4690ff;
    font-size: 15px;
    text-decoration: none;
    &:hover {
      color: #3b6fbd;
    }
  }
}

.dark_mode_textArea {
  font-size: 15px;
  height: 70px !important;
  background-color: #e8f0fe0d !important;
  color: white !important;
  border: none;
}

.dark_mode_ForgotSection {
  display: grid;
  grid-template-columns: 1fr 1fr;
  position: relative;
  z-index: 9;
  margin: -10px 1px 22px;
  a {
    color: #fff !important;
    font-size: 15px;
    text-decoration: none;
    &:hover {
      color: #70a9ff;
    }
  }
  .admin_login_lnk {
    text-align: right;
  }
}