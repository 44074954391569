@import '../../style/custonVariable';
.Maincontainer{
    padding: 15px 25px;
    .BackButtons{
        text-align: right;
    }
    .CardBody{
        padding: 15px;
        .formGroup{
            text-align: left;
            margin-bottom: 15px;
        }
    }
    .borderNone{
        border: 1px solid #dee2e6;
    }
}