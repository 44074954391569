.parent-business {
    display: flex;
    justify-content: center;
    margin-top: 7%;
}

.input-field-business {
    /* padding: 6px 0 9px 10%; */
    /* display: flex;
    justify-content: center;
    /* width : "80%"; */
    
    margin-top: 2%; 
    width: 125%;
    /* font-size: 0.75rem; */
    /* z-index: 1000 !important;
    height: 34px;
    color: black !important; */
    /* padding: 5px 12px !important; */
    /* border-radius: 3px !important; */
    /* border: 1px solid #cfdadd !important; */
    /* height: 34px; */
}
.input-field-date {
   margin-top: 2%;
   /* height: 40px; */
}


.button-business {
    padding: 5% 0 5% 45%;

}

.container-business {
    display: flex;
    justify-content: center;
    /* min-height: 50vh; */
    padding: 5%,
}

.card-business {
    /* padding: 20px 0; */
    width: 70%;
    max-height: 55%;
    content-wrap: wrap;
    padding-left: "3%";
    box-shadow: 0 0 10px 5px rgba(213, 213, 213, 0.4);
    display: flex;
    justify-content: center;
    border-radius: 10px;
}

.label-business {
    font-size: clamp(10px, 2vw, 18px);
}


.inputBox{
    width: 80%;
    height: 35px;
    border-radius: 4px;
    border: 1px solid #cfcfcf;
    padding: 5px;
    font-size: 12px;
}

.submit-business {
    width: 18%;
    height: 60%;
    margin: 0 0 4% 0;
    border-radius: 5px;
}


.error-outline {
    border: 1px solid red;
  }
  
.custom-datepicker-business {
    width: 80px
}

/* .react-datepicker__input-container{
    width: 100%;
    height: 40px;
    } */

.react-datepicker__input-container>input{
width: 15%;
height: 40px;
border-radius: 4px;
border: 1px solid #000;
display: block;
margin-right: 200%;
}

.date-picker-div-business {
    position: relative;
    top: 3%;
    left: 75%;
    /* width: 100%; */
}

